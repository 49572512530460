<template>
  <MatchGameLayout
    class="image-match"
    :class="{
      'image-match--host': isHost,
      'image-match--textify': textify
    }"
    v-model="facts"
    :isDraggable="isDisabled"
    :checkMove="handleMove"
    @handleDragEnd="handleDragEnd"
  >
    <template #top-items>
      <MatchGameCard
        class="image-match__option"
        v-for="player in players"
        :transparent="mission.imagesTransparentBg"
        :key="`top-image-${player.index}`"
        :imageURL="player.image"
      />
    </template>

    <template #bottom-items>
      <template v-if="textify">
        <div
          v-for="item in facts"
          :key="`bottom-image-${item.index}`"
          class="image-match__option image-match__option--draggable image-match__option--text"
          @mousedown="onMouseDown(item.index)"
          @mouseup="onMouseDown(null)"
          @dragend="onMouseDown(null)"
        >
          <FactCard
            :selected="item.id === target"
            :disabled="isDisabled"
            :correct="item.image"
          />
        </div>
      </template>
      <template v-else>
        <MatchGameCard
          v-for="item in facts"
          class="image-match__option image-match__option--draggable"
          :disabled="isDisabled"
          :transparent="mission.pairImagesTransparentBg"
          :key="`bottom-image-${item.index}`"
          :imageURL="item.image"
          @mousedown="onMouseDown(item.index)"
          @mouseup="onMouseDown(null)"
          @dragend="onMouseDown(null)"
        />
      </template>
    </template>

    <template #submit>
      <RtbButton
        class="font-bold capitalize text-lg px-3"
        @click="onSubmit()"
        :disabled="!showSubmit"
      >
        {{ submitText }}
      </RtbButton>
    </template>

    <template #pointer>
      <DragPointer
        rootClassname=".image-match"
        elementClassname=".image-match__option--draggable"
      />
    </template>
  </MatchGameLayout>
</template>

<script>
import { mapGetters } from "vuex"
import draggable from "vuedraggable"
import { RtbButton } from "@/components/ui"
import User from "@shared/User"

import DragPointer from "./Parts/DragPointer.vue"
import MatchGameLayout from "./Parts/MatchGameLayout.vue"
import FactCard from "./Parts/FactCard.vue"

const MatchGameCard = () =>
  import("@/components/GroupTeams/Common/Player/MatchGameCard.vue")

import useMatchGame from "./useMatchGame"

export default {
  name: "ImageMatch",
  components: {
    MatchGameLayout,
    MatchGameCard,
    DragPointer,
    RtbButton,
    draggable,
    FactCard
  },
  setup() {
    const { isDisabled, showSubmit, submitText } = useMatchGame()

    function isValidHttpUrl(string) {
      let url

      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return url.protocol === "http:" || url.protocol === "https:"
    }

    return { isDisabled, showSubmit, submitText, isValidHttpUrl }
  },
  data() {
    return {
      facts: [],
      target: null
    }
  },
  created() {
    this.buffer = {
      movingIndex: 0,
      futureIndex: 0
    }
  },
  watch: {
    // watch for the remote and updte the local copy
    otherTeamFacts: {
      handler: function (value = []) {
        if (this.isPresenter && this.facts.length) return
        this.facts = [...value]
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["isScribe"]),
    ...mapGetters("auth", ["isAudit", "isSpectator", "isHost", "user"]),
    ...mapGetters({
      mission: "getCurrentMission",
      teams: "chats"
    }),
    textify() {
      return !this.isValidHttpUrl(this.facts[0]?.image)
    },
    isPresenter() {
      return User.isPresenter(this.user)
    },
    otherTeamFacts() {
      return Array.isArray(this.team?.otherTeamFacts)
        ? this.team.otherTeamFacts
        : []
    },
    players() {
      const images = this.mission?.representImages
      return Array.isArray(images)
        ? images.map((image, index) => ({ image, index }))
        : []
    },
    team() {
      return this.$store.getters["group/canViewerSelectGlobalTeam"]
        ? this.teams[this.$store.getters["group/globalTeamID"]]
        : this.teams[this.user?.teamID]
    }
  },
  methods: {
    handleDragEnd() {
      const { movingIndex, futureIndex } = this.buffer
      const futureItem = this.facts[futureIndex]
      const movingItem = this.facts[movingIndex]
      if (futureItem !== undefined && movingItem !== undefined) {
        const facts = this.facts.slice(0)
        facts[futureIndex] = movingItem
        facts[movingIndex] = futureItem

        this.facts = facts

        this.onUpdate()
      }
    },
    handleMove(e) {
      const { index: movingIndex, futureIndex } = e.draggedContext
      this.buffer = { movingIndex, futureIndex }
      // disable sort
      return false
    },
    onMouseDown(index) {
      this.target = index
    },
    onTeamUpdate(teamID, facts) {
      const obj = {}
      obj.otherTeamFacts = facts
      obj.id = teamID
      this.$store.dispatch("updateTeam", obj)
    },
    // update current (local) team
    onUpdate() {
      this.onTeamUpdate(this.team.id, this.facts)
    },
    onSubmit() {
      if (this.facts.length !== this.players.length) return

      const correct = this.facts.filter(
        (obj, idx) => this.players[idx].index === obj.index
      )
      const incorrect = this.facts.filter(
        (obj, idx) => this.players[idx].index !== obj.index
      )

      const numOfFacts = this.facts.length
      const num = parseInt(this.mission?.points) || 0
      const points = parseInt(num / numOfFacts)
      var score = correct.length * points
      const answer = []
      if (this.facts.length == correct.length) score = num

      correct.forEach(obj => {
        answer.push({
          index: obj.index,
          image: obj.image,
          result: true
        })
      })

      incorrect.forEach(obj => {
        answer.push({
          index: obj.index,
          image: obj.image,
          result: false
        })
      })

      this.$store.dispatch("addFactMatchPlay", { score, answer })
    }
  }
}
</script>

<style lang="scss">
.image-match {
  --image-match-col-width: 18vh;

  &--host {
    --image-match-col-width: 16vh;
  }

  .orientation-portrait & {
    --image-match-col-width: 100%;
  }

  .orientation-landscape & {
    --image-match-col-width: auto;

    @supports not (aspect-ratio: 1) {
      --image-match-col-width: calc((var(--app-height) / 2) - 54px);
    }
  }

  &--textify {
    .match-game-layout__draggable-row {
      height: 33.333% !important;
    }
    .match-game-layout__top {
      height: 66.666% !important;
    }
  }

  &__option {
    width: var(--image-match-col-width);
    max-width: var(--image-match-col-width);
    flex-shrink: 0;

    .orientation-landscape & {
      height: 100%;

      @supports not (aspect-ratio: 1) {
        --image-match-col-width: calc((var(--app-height) / 2) - 54px);
      }
    }

    &--draggable {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }

    &--text {
      aspect-ratio: 2/1;
      & > div {
        margin: 2px;
        height: 100%;
        padding: 0 2px;
        font-size: 16px;
      }
    }
  }
}
</style>
