var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MatchGameLayout", {
    staticClass: "image-match",
    class: {
      "image-match--host": _vm.isHost,
      "image-match--textify": _vm.textify,
    },
    attrs: { isDraggable: _vm.isDisabled, checkMove: _vm.handleMove },
    on: { handleDragEnd: _vm.handleDragEnd },
    scopedSlots: _vm._u([
      {
        key: "top-items",
        fn: function () {
          return _vm._l(_vm.players, function (player) {
            return _c("MatchGameCard", {
              key: "top-image-" + player.index,
              staticClass: "image-match__option",
              attrs: {
                transparent: _vm.mission.imagesTransparentBg,
                imageURL: player.image,
              },
            })
          })
        },
        proxy: true,
      },
      {
        key: "bottom-items",
        fn: function () {
          return [
            _vm.textify
              ? _vm._l(_vm.facts, function (item) {
                  return _c(
                    "div",
                    {
                      key: "bottom-image-" + item.index,
                      staticClass:
                        "image-match__option image-match__option--draggable image-match__option--text",
                      on: {
                        mousedown: function ($event) {
                          return _vm.onMouseDown(item.index)
                        },
                        mouseup: function ($event) {
                          return _vm.onMouseDown(null)
                        },
                        dragend: function ($event) {
                          return _vm.onMouseDown(null)
                        },
                      },
                    },
                    [
                      _c("FactCard", {
                        attrs: {
                          selected: item.id === _vm.target,
                          disabled: _vm.isDisabled,
                          correct: item.image,
                        },
                      }),
                    ],
                    1
                  )
                })
              : _vm._l(_vm.facts, function (item) {
                  return _c("MatchGameCard", {
                    key: "bottom-image-" + item.index,
                    staticClass:
                      "image-match__option image-match__option--draggable",
                    attrs: {
                      disabled: _vm.isDisabled,
                      transparent: _vm.mission.pairImagesTransparentBg,
                      imageURL: item.image,
                    },
                    on: {
                      mousedown: function ($event) {
                        return _vm.onMouseDown(item.index)
                      },
                      mouseup: function ($event) {
                        return _vm.onMouseDown(null)
                      },
                      dragend: function ($event) {
                        return _vm.onMouseDown(null)
                      },
                    },
                  })
                }),
          ]
        },
        proxy: true,
      },
      {
        key: "submit",
        fn: function () {
          return [
            _c(
              "RtbButton",
              {
                staticClass: "font-bold capitalize text-lg px-3",
                attrs: { disabled: !_vm.showSubmit },
                on: {
                  click: function ($event) {
                    return _vm.onSubmit()
                  },
                },
              },
              [_vm._v(" " + _vm._s(_vm.submitText) + " ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "pointer",
        fn: function () {
          return [
            _c("DragPointer", {
              attrs: {
                rootClassname: ".image-match",
                elementClassname: ".image-match__option--draggable",
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.facts,
      callback: function ($$v) {
        _vm.facts = $$v
      },
      expression: "facts",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }